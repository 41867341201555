import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
import axios from "axios";
import { getToken } from "@/utils";
import { getValidToken } from '@/utils/storage';
export default {
  queryPrjTimeData: function queryPrjTimeData(prjId) {
    return request.get("/app-doc-center/v1/drawing/charts/prj-time-gml?prjId=".concat(prjId)).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  getDicContent: function getDicContent() {
    return request.get("/app-doc-center/v1/sys/getRoleTagList"); // return request.get(`/app/v1/dic/list-dicContent?dicCode=RoleTag`)
  },
  queryPrjTimeData_nogml: function queryPrjTimeData_nogml(prjId) {
    return request.get("/app-doc-center/v1/drawing/charts/prj-time?prjId=".concat(prjId)).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryPrjTimeData3D: function queryPrjTimeData3D(prjId) {
    return request.get("/app-doc-center/v1/drawing/charts/prj-time-gml?prjId=".concat(prjId)).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryPrjTimeData3D_nogml: function queryPrjTimeData3D_nogml(prjId) {
    return request.get("/app-doc-center/v1/drawing/charts/prj-time?prjId=".concat(prjId)).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryPrjIssueTotalData: function queryPrjIssueTotalData(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjIssueTotalData_nogml: function queryPrjIssueTotalData_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjIssueTotalData3D: function queryPrjIssueTotalData3D(params) {
    return request.post("/app-doc-center/v1/qm-drawing-charts/listByFolderList-gml", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryPrjIssueTotalData3D_nogml: function queryPrjIssueTotalData3D_nogml(params) {
    return request.post("/app-doc-center/v1/qm-drawing-charts/listByFolderList", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryDrawingFuncsData: function queryDrawingFuncsData(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/drawing-user-function-list-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryDrawingFuncsData_nogml: function queryDrawingFuncsData_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/drawing-user-function-list', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryDrawingFuncsData3D: function queryDrawingFuncsData3D(params) {
    //邱接口修改
    return request.post("/app-doc-center/v1/qm-drawing-charts/userFunctionListByFolderList-gml", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryDrawingFuncsData3D_nogml: function queryDrawingFuncsData3D_nogml(params) {
    //邱接口修改
    return request.post("/app-doc-center/v1/qm-drawing-charts/userFunctionListByFolderList", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryIssueTypesData: function queryIssueTypesData(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getQuestionTypeDistribution-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryIssueTypesData_nogml: function queryIssueTypesData_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getQuestionTypeDistribution', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryIssueTypesData3D: function queryIssueTypesData3D(params) {
    //邱接口修改
    return request.post("/app-doc-center/v1/qm-drawing-charts/typeQuestionsNumberByFolderList-gml", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryIssueTypesData3D_nogml: function queryIssueTypesData3D_nogml(params) {
    //邱接口修改
    return request.post("/app-doc-center/v1/qm-drawing-charts/typeQuestionsNumberByFolderList", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryIssueSpecsData: function queryIssueSpecsData(params) {
    //
    return request.post('/app-doc-center/v1/cad/problem-statistics/getQuestionSpecDistribution-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryIssueSpecsData_nogml: function queryIssueSpecsData_nogml(params) {
    //
    return request.post('/app-doc-center/v1/cad/problem-statistics/getQuestionSpecDistribution', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryIssueSpecsData3D: function queryIssueSpecsData3D(params) {
    //邱接口修改
    return request.post("/app-doc-center/v1/qm-drawing-charts/specQuestionsNumberByFolderList-gml", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryIssueSpecsData3D_nogml: function queryIssueSpecsData3D_nogml(params) {
    //邱接口修改
    return request.post("/app-doc-center/v1/qm-drawing-charts/specQuestionsNumberByFolderList", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryIssueResolveData: function queryIssueResolveData(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/question-solving-rate-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryIssueResolveData_nogml: function queryIssueResolveData_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/question-solving-rate', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryIssueResolveData3D: function queryIssueResolveData3D(params) {
    //邱接口修改
    return request.post("/app-doc-center/v1/qm-drawing-charts/questionStatusListByFolderList-gml", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryIssueResolveData3D_nogml: function queryIssueResolveData3D_nogml(params) {
    //邱接口修改
    return request.post("/app-doc-center/v1/qm-drawing-charts/questionStatusListByFolderList", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  getOnlineDuration: function getOnlineDuration(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getOnlineDuration-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  getOnlineDuration_nogml: function getOnlineDuration_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getOnlineDuration', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryFunctionProblems: function queryFunctionProblems(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getFunctionProblemsDistribution-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryFunctionProblems_nogml: function queryFunctionProblems_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getFunctionProblemsDistribution', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  getFunctionNameList: function getFunctionNameList(prjId) {
    //未修改
    return request.get("/app-doc-center/v1/cad/problem-statistics/getFunctionNameList?prjId=".concat(prjId)).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPersonnelProblems: function queryPersonnelProblems(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getPersonnelProblemsDistribution-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPersonnelProblems_nogml: function queryPersonnelProblems_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getPersonnelProblemsDistribution', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjInfoUserInfoList: function queryPrjInfoUserInfoList(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getDrawingStaffRanking-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjInfoUserInfoList_nogml: function queryPrjInfoUserInfoList_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getDrawingStaffRanking', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  getDwgFileNum: function getDwgFileNum(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getCadProblemNum-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  getDwgFileNum_nogml: function getDwgFileNum_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/getCadProblemNum', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  getDwgProblem: function getDwgProblem(params) {
    return request.post("/app-doc-center/v1/cad/problem-statistics/getDrawingProblemDistribution-gml", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  getDwgProblem_nogml: function getDwgProblem_nogml(params) {
    return request.post("/app-doc-center/v1/cad/problem-statistics/getDrawingProblemDistribution", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  exportPrjPPT: function exportPrjPPT(params) {
    // var token = 'eyJhbGciOiJIUzI1NiJ9.eyJyIjoxLCJjIjoiMTEiLCJ0IjoiOTk5OTk5OTk5IiwidSI6IjE2MzExMjM5MDI0MjUxMzMwNTYiLCJkb21haW4iOiJxZGluZ25ldC5jb20iLCJuIjoi5p2O5L2z5LyfIiwiZXhwIjoxNjc5Mzg0NTMxfQ.aDeOrf11YiHUW7YF_DMJjH4gGzn30y5G-9G3rK3TgHo'
    var api = 'lzz-productcheck-uat.longfor.com/halo/api/';
    var token = getValidToken(); // return axios({
    //   url: `https://lzz-productcheck-uat.longfor.com/halo/api/app-doc-center/v1/cad/problem-statistics/exportPPT-gml`,
    //   headers:{token},
    //   method: 'post',
    //   responseType: "blob",
    //   data:params
    // }) 

    return request.post('/app-doc-center/v1/cad/problem-statistics/exportPPT-gml', params, {
      headers: {
        token: token
      }
    }).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  exportPrjPPT_nogml: function exportPrjPPT_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/exportPPT', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  exportPrjPPT3D: function exportPrjPPT3D(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/exportPPT3D-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  exportPrjPPT3D_nogml: function exportPrjPPT3D_nogml(params) {
    return request.post('/app-doc-center/v1/cad/problem-statistics/exportPPT3D', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  exportPrjPPTStatus: function exportPrjPPTStatus(id) {
    var token = getValidToken();
    return request.get("/app-doc-center/v1/drawing/exportExcel/is-excel-exist/".concat(id), {
      headers: {
        token: token
      }
    }).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  exportPrjExcel: function exportPrjExcel(params) {
    var token = getValidToken();
    return axios({
      url: process.env.VUE_APP_API + "/app-doc-center/v1/cad/export-prj-excel-gml",
      headers: {
        token: token
      },
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  exportPrjExcel_nogml: function exportPrjExcel_nogml(params) {
    var token = getValidToken();
    return axios({
      url: process.env.VUE_APP_API + "/app-doc-center/v1/cad/export-prj-excel",
      headers: {
        token: token
      },
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  turndwgResult2D: function turndwgResult2D(params) {
    //上传审图结果
    return request.post('/app-doc-center/v1/problem/manage/plm-upload-check-result-2d-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  turndwgResult3D: function turndwgResult3D(params) {
    //上传审图结果
    return request.post('/app-doc-center/v1/problem/manage/plm-upload-check-result-3d-gml', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  judgeButton: function judgeButton(prjId, stageId, type, versionId) {
    //按钮是否可点击
    return request.get("/app/v1/judgeButton-gml?prjId=".concat(prjId, "&stageId=").concat(stageId, "&type=").concat(type, "&versionId=").concat(versionId)).then(function (res) {
      return res;
    });
  },
  judgeButton_nogml: function judgeButton_nogml(prjId, stageId, type) {
    //按钮是否可点击
    return request.get("/app/v1/judgeButton?prjId=".concat(prjId, "&stageId=").concat(stageId, "&type=").concat(type)).then(function (res) {
      return res;
    });
  },
  queryMapLengthData3D: function queryMapLengthData3D(params) {
    //邱接口修改
    return request.post("/app-doc-center/v1/qm-drawing-charts/userListByPrjIdAndFolderList-gml", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  queryMapLengthData3D_nogml: function queryMapLengthData3D_nogml(params) {
    //邱接口修改
    return request.post("/app-doc-center/v1/qm-drawing-charts/userListByPrjIdAndFolderList", params).then(function (res) {
      return res;
    }).catch(function (err) {
      return console.log(err);
    });
  },
  //项目人员参审统计 导出
  getDrawingStaffRankingExcel: function getDrawingStaffRankingExcel(params) {
    var token = getValidToken();
    return axios({
      url: process.env.VUE_APP_API + "/app-doc-center/v1/cad/problem-statistics/getDrawingStaffRanking-gml/export",
      headers: {
        token: token
      },
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  //2d审图人员及在线时长统计
  exportOnlineDurationGMLExcel: function exportOnlineDurationGMLExcel(params) {
    var token = getValidToken();
    return axios({
      url: process.env.VUE_APP_API + "/app-doc-center/v1/cad/problem-statistics/exportOnlineDurationGML",
      headers: {
        token: token
      },
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  //2d审图角色问题分布统计
  exportPersonnelProblemsDistributionGMLExcel: function exportPersonnelProblemsDistributionGMLExcel(params) {
    var token = getValidToken();
    return axios({
      url: process.env.VUE_APP_API + "/app-doc-center/v1/cad/problem-statistics/exportPersonnelProblemsDistributionGML",
      headers: {
        token: token
      },
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  //2d审图人员问题分布统计
  exportFunctionProblemsDistributionGMLExcel: function exportFunctionProblemsDistributionGMLExcel(params) {
    var token = getValidToken();
    return axios({
      url: process.env.VUE_APP_API + "/app-doc-center/v1/cad/problem-statistics/exportFunctionProblemsDistributionGML",
      headers: {
        token: token
      },
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  //3d点评人员及在线时长统计
  drawingchartsExcel: function drawingchartsExcel(params) {
    var token = getValidToken();
    return axios({
      url: process.env.VUE_APP_API + "/app-doc-center/v1/qm-drawing-charts/export",
      headers: {
        token: token
      },
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  //图纸问题分布 导出
  exportDrawingProblemDistribution: function exportDrawingProblemDistribution(params) {
    var token = getValidToken();
    return axios({
      url: process.env.VUE_APP_API + "/app-doc-center/v1/cad/problem-statistics/exportDrawingProblemDistribution-gml",
      headers: {
        token: token
      },
      method: 'post',
      responseType: "blob",
      data: params
    });
  }
};